<template>
  <div>
    <buildFieldsRows
      :templateContent="upperParams"
      v-model="value"
      languageIndex="checkStep"
    ></buildFieldsRows>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <containerTitle title=" check for"></containerTitle>
      <div class="pa-2">
        <lineFieldsArray
          :fieldAttrRow="{}"
          :fields="evalFields"
          :templateContent="result"
          :value="value.eval"
          @line-field-input="checkInput(...arguments)"
          :headerTitle="true"
          :addable="true"
        ></lineFieldsArray>
      </div>
    </v-card>
  </div>
</template>
<script>
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: {
    buildFieldsRows,
    lineFieldsArray,
    containerTitle,
  },

  data() {
    return {
      upperParams: {
        fields: {
          msgType: {
            type: "select",
            options: [["RATTUX_receive_sms", "Recieve SMS"]],
            fieldAttrInput: { class: "required " },
          },
          last: {
            type: "text",

            fieldAttrInput: { class: "required" },
            default: "300",
          },
          onError: {
            type: "select",
            options: [
              ["abort", "Abort"],
              ["ignore", "Ignore"],
            ],
            fieldAttrInput: { class: "required " },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
      },

      evalFields: {
        content: {
          type: "text",

          name: "Text",
          fieldAttrInput: { class: "required " },
        },
        operator: {
          type: "select",
          associatedOption: [
            ["contains", "Contains"],
            ["does not contain", "Does not contain"],
          ],
          name: "Check",
          fieldAttrInput: { class: "required " },
        },
        remove: lineButtons.remove,
      },
    };
  },
  computed: {
    value: {
      get() {
        let value = {
          msgType: "RATTUX_receive_sms",
          last: "300",
          onError: "ignore",
          eval: [{ content: "", operator: "" }],
        };
        if (typeof this.stepStoredValue != "undefined") {
          value = this.stepStoredValue;
        }
        return value;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
  },
  methods: {
    checkInput(value) {
      let localStoredStep = { ...this.stepStoredValue };
      this.$set(localStoredStep, "eval", value);

      this.$emit("stored-value-changed", localStoredStep);
    },
  },

  props: [
    "result",
    "stepStoredValue",
    "stepType",
    "groupedStepDependentInfo",
    "stepID",
    "additionalStepsInfo",
  ],
};

// function renderCHECK($stepTypeSelector,fieldsObject,stepType){

//     var $requestTable=$stepTypeSelector.closest('.requestTable');
//     var $step=$stepTypeSelector.closest('.step');
//     var $testCasesGroup=$step.closest(".testCasesGroup");
//     var $additionalFields=$requestTable.find('.additionalFields');
//     //var  stepId=$step.attr('id');
//     var  stepId=$step.attr('data-stepid');
//     let value={ "msgType":"RATTUX_receive_sms", "last":"300", "onError":"ignore", "eval":[{content:"",operator:""}]};
//     if ($testCasesGroup.data( "templateValue")[stepId] && $testCasesGroup.data( "templateValue")[stepId][stepType] ){
//       value=$testCasesGroup.data( "templateValue")[stepId][stepType];
//     }
//     $additionalFields.empty();
//     var $msgTypeRow=$("<tr class='msgTypeRow'></tr>").appendTo($additionalFields);
//     $msgTypeRow.append("<td>Message Type*</td>");
//     $msgTypeRow.append('<td class="msgTypetd"></td>');

//     let messageTypes=[["RATTUX_receive_sms", "Recieve SMS"]];
//     $msgTypeRow.find(".msgTypetd").append(makeSelector(messageTypes, value["msgType"],"required msgType"));

//     let $lastRow=$("<tr class='lastRow'></tr>").appendTo($additionalFields);
//     $lastRow.append("<td>Check Last*</td>");
//     $lastRow.append('<td class="lasttd"></td>');

//     let $lastValueInput=$('<input class="required number lastValue" type="number" />');
//     $lastValueInput.val(value["last"]);
//     $lastRow.find(".lasttd").append($lastValueInput);

//     var $onErrorRow=$("<tr class='onErrorRow'></tr>").appendTo($additionalFields);
//     $onErrorRow.append("<td>On Error*</td>");
//     $onErrorRow.append('<td class="onErrortd"></td>');

//     let onError=[["abort", "Abort"],["ignore", "Ignore"]];
//     $onErrorRow.find(".onErrortd").append(makeSelector(onError, value["onError"],"required onError"));

//     var $checkRow=$("<tr class='checkRow'></tr>").appendTo($additionalFields);
//     $checkRow.append("<td colspan='3' >Check for:<div class='evalContainer'></div><div class='evalAdder'><button href='javascript:void(0);' onclick='addEvalCheck(this);return false;' ><i class='fas fa-plus-square fs16'></i> Add Check</button></div></td>");
//     let $evalContainer=$checkRow.find(".evalContainer");
//     if (typeof value.eval=="undefined"){
//       value.eval=[{content:"",operator:""}];
//     }
//     value.eval.forEach(function (evalValue){
//       addEvalLine($evalContainer, evalValue);
//     });
//     toggleHelpTooltip();
//   }

//   function addEvalCheck(caller){
//     let $evalContainer=$(caller).closest("td").find(".evalContainer");
//     let evalValue={ "content":"", "operator":"contains" };
//     addEvalLine($evalContainer, evalValue);
//     return false;
//   }

//   function addEvalLine($evalContainer, evalValue){
//     let operators=[["contains", "Contains"],["does not contain", "Does not contain"]];
//     var $evalLine=$('<div class="evalLine" ><div style="min-width: 111px;display: inline-block;">Text*</div><div style="display: inline-block;"><input class="required evalString" type="text"/></div><div class="operatorContainer" style="display: inline-block;"></div><div class="" style="display: inline-block;"><i title="Delete" class="fa fa-trash-o fa-lg iconRed" style="margin-left:3px;cursor: pointer;" onclick="removeCheckEvalLine(this);"></i></div></div>').appendTo($evalContainer);
//     let $evalString=$evalLine.find(".evalString");
//     $evalString.val( evalValue["content"]);
//     var id= $evalString.uniqueId().attr('id');
//     $evalString.attr("name",id);
//     $evalLine.find(".operatorContainer").html(makeSelector(operators, evalValue["operator"],"required operator"));
//   }

//   function removeCheckEvalLine(caller){
//     let $caller=$(caller);
//     if ($caller.closest(".evalContainer").find(".evalLine").length>1){
//       $caller.closest(".evalLine").remove();
//     }

//   }
</script>